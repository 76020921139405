import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/ui/Link';

import {
  HoverUnderline,
  Wrapper,
} from './HorizontalMenuLink.styles';

const HorizontalMenuLink = ({
  active,
  children,
  hovered,
  href,
  route,
  params,
  scroll,
  trackPage,
}) => (
  <Link href={href} route={route} params={params} active={active} scroll={scroll} onClick={() => trackPage(route)}>
    <Wrapper active={active}>
      {children}
      {hovered ? <HoverUnderline /> : null}
    </Wrapper>
  </Link>
);

HorizontalMenuLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  active: PropTypes.bool,
  hovered: PropTypes.bool,
  href: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  params: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  route: PropTypes.string,
  scroll: PropTypes.bool,
  trackPage: PropTypes.func,
};

HorizontalMenuLink.defaultProps = {
  active: true,
  hovered: false,
  href: null,
  route: null,
  scroll: true,
  params: {},
  trackPage: () => {},
};

export default HorizontalMenuLink;
