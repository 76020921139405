import { defineMessages } from 'react-intl';

export default defineMessages({
  investment: {
    id: 'QuickSearchForm.investment',
    defaultMessage: 'Wybierz Inwestycje',
  },
  price: {
    id: 'QuickSearchForm.price',
    defaultMessage: 'Cena',
  },
  surface: {
    id: 'QuickSearchForm.surface',
    defaultMessage: 'POWIERZCHNIA',
  },
  rooms: {
    id: 'QuickSearchForm.rooms',
    defaultMessage: 'Pokoje',
  },
  goToAdvanced: {
    id: 'QuickSearchForm.goToAdvanced',
    defaultMessage: 'Wyszukiwanie zaawansowane',
  },
});
