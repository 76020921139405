import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Dot = styled.div`
  margin: 5px;
  width: 8px;
  height: 8px;
  background-color: ${(props) => props.theme.backgroundColor};
  border-radius: 50%;
  cursor: pointer;

  ${(props) => props.isLocalVariant && css`
    background-color: ${props.theme.secondary8Color};
  `}
  ${(props) => props.active && css`
    background-color: ${props.theme.primary1Color};
  `}
`;

export { Dot, Wrapper };
