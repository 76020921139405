import React from 'react';
import { ContactPersonShape } from 'components/HeaderMobile/components/ContactDetails/components/ContactPerson/ContactPerson.shapes';
import {
  Wrapper, Image, Name, Position, Phone,
} from './ContactPerson.styles';

const ContactPerson = ({ contactPerson }) => (
  <Wrapper>
    <Image src={contactPerson.image} alt="Contact person" />
    <Name>{contactPerson.name}</Name>
    <Phone>{contactPerson.phone}</Phone>
    <Position>{contactPerson.position}</Position>
  </Wrapper>
);

ContactPerson.propTypes = {
  contactPerson: ContactPersonShape.isRequired,
};

export default ContactPerson;
