import styled from 'styled-components';

const RangeSliderDisplay = styled.div`
  color: ${(props) => props.theme.primary3Color};
`;

const Value = styled.div`
  background-color: transparent;
  font-size: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: ${(props) => props.theme.primary3Color};
  text-transform: uppercase;

  span {
    margin: 1px 5px 3px 5px;
    font-size: 13px;
  }

  small span {
    font-size: 9px;
    font-weight: 700;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 12px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) and (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 13px;
  }
`;

export { RangeSliderDisplay, Value };
