import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import FullArrow from 'components/icons/FullArrow';
import Link from 'components/ui/Link';

import { PathPartShape } from './Breadcrumbs.shapes';

import {
  Delimiter,
  PathPart,
  PathWrapper,
  Content,
  ImageWrapper,
  Image,
  BreadcrumbsWrapper,
} from './Breadcrumbs.styles';
import { VisibilityContainer } from '../../utils/styles';

const Breadcrumbs = ({
  imageUrl,
  pathParts,
  theme,
  onImageLoad,
}) => (
  <BreadcrumbsWrapper>
    <ImageWrapper>
      <Image src={imageUrl} onLoad={onImageLoad} />
    </ImageWrapper>
    <VisibilityContainer md lg>
      <Content id="scroll">
        <PathWrapper>
          {
            pathParts.map((el, key) => (
              <React.Fragment key={el.id}>
                <Link route={el.route} params={el.params}><PathPart>{el.name}</PathPart></Link>
                {
                  key === pathParts.length - 1 ? null : (
                    <Delimiter>
                      <FullArrow fill={theme.primary1Color} />
                    </Delimiter>
                  )
                }
              </React.Fragment>
            ))
          }
        </PathWrapper>
      </Content>
    </VisibilityContainer>
  </BreadcrumbsWrapper>
);

Breadcrumbs.propTypes = {
  theme: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  imageUrl: PropTypes.string,
  pathParts: PropTypes.arrayOf(PathPartShape),
  onImageLoad: PropTypes.func,
};

Breadcrumbs.defaultProps = {
  imageUrl: null,
  pathParts: [],
  onImageLoad: () => {},
};

export default withTheme(Breadcrumbs);
