import withSizes from 'react-sizes';
import breakpoints from 'theme/breakpoints';

const withSizesUtil = () => withSizes(({ width }) => {
  const sizes = {
    isLargeScreen: false,
    isMediumScreen: false,
    isSmallScreen: false,
    isMobileScreen: false,
    screenType: null,
  };

  if (width >= parseInt(breakpoints.lg)) {
    sizes.isLargeScreen = true;
    sizes.screenType = 'lg';
  } else if (width >= parseInt(breakpoints.md)) {
    sizes.isMediumScreen = true;
    sizes.screenType = 'md';
  } else if (width >= parseInt(breakpoints.sm)) {
    sizes.isSmallScreen = true;
    sizes.screenType = 'sm';
  } else {
    sizes.isMobileScreen = true;
    sizes.screenType = 'xs';
  }

  return {
    ...sizes,
    screenType: sizes.screenType,
    screenWidth: width,
  };
});

export default withSizesUtil;
