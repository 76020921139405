import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 550px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: calc(100% + 15px);
    margin-bottom: 20px;
  }
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.p`
  margin-left: 4px;
  font-size: 9px;
  color: ${(props) => props.theme.primary3Color};
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 1.1px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 10px;
    font-weight: 500;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1px;
  padding-bottom: 3px;
`;

export {
  Wrapper, Container, Title, Row,
};
