import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import _isNull from 'lodash/isNull';
import { RangeSliderDisplay, Value } from './Display.styles';
import messages from './Display.messages';

const Display = ({
  firstValue,
  secondValue,
  format,
}) => (
  <RangeSliderDisplay>
    <Value>
      <small>
        <FormattedMessage {...messages.from} />
      </small>
      {!_isNull(firstValue)
        ? <FormattedNumber value={firstValue} maximumFractionDigits={0} /> : null}
      <small>
        <FormattedMessage {...messages.to} />
      </small>
      {!_isNull(secondValue)
        ? <FormattedNumber value={secondValue} maximumFractionDigits={0} /> : null}
      {format}
    </Value>
  </RangeSliderDisplay>
);

Display.propTypes = {
  firstValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.string,
  secondValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOf(['long', 'narrow']),
};

Display.defaultProps = {
  firstValue: null,
  format: null,
  secondValue: null,
  width: 'long',
};

export default Display;
