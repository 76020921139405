import React from 'react';
import PropTypes from 'prop-types';
import { Router } from 'Router';
import includes from 'lodash/includes';

import GoogleMaps from 'components/GoogleMaps';
import { MapPointShape } from 'components/GoogleMaps/GoogleMaps.shapes';
import ContactUsBlock from 'components/content/ContactUsBlock';
import { ContactUsEntryShape } from 'components/content/ContactUsBlock/ContactUsBlock.shapes';
import ContactUsTitle from 'components/content/ContactUsBlock/components/ContactUsTitle';

import { ContainerMain } from 'utils/styles';
import config from 'config';
import withSizes from 'utils/withSizes';

import { Waypoint } from 'react-waypoint';
import trackYouleadCustomEvent from 'utils/YouLead/TrackYouleadCustomEvent';
import {
  Column,
  RightContainer,
  Wrapper,
} from './ContactWithMap.styles';

const ContactWithMap = ({
  contactItems,
  mapPoints,
  showContactData,
  staticMapUrl,
  centered,
  screenType,
  onPositionChange,
}) => {
  const {
    desktopMapZoom,
    mobileMapZoom,
    cityCenter,
  } = config;
  const isMediumOrBigger = includes(['md', 'lg'], screenType);
  const zoomValue = isMediumOrBigger ? desktopMapZoom : mobileMapZoom;
  const zoom = centered ? zoomValue : null;
  const center = centered ? cityCenter : null;
  return (
    <Waypoint
      bottomOffset={400}
      onPositionChange={({ currentPosition }) => {
        onPositionChange({ id: config.locationIds.localization, position: currentPosition });
      }}
    >
      <Wrapper>
        <Column id={config.locationIds.investmentMap}>
          <GoogleMaps
            points={mapPoints}
            onMarkerClick={(id, slug, type, route, name) => {
              if (type === 'Investment' && id && slug) {
                Router.pushRoute('investmentDescriptionDetails', { id: slug });
              } else if (type === 'Office' && route) {
                window.open(route, '_blank');
                trackYouleadCustomEvent('clickGoogleRouteMarker', { name });
              }
            }}
            staticMapUrl={staticMapUrl}
            center={center}
            zoom={zoom}
          />
        </Column>
        { !showContactData ? null : (
          <ContainerMain>
            <RightContainer>
              <ContactUsBlock
                title={<ContactUsTitle />}
                items={contactItems}
                transparentBackground
                alignCenter
              />
            </RightContainer>
          </ContainerMain>
        )}
      </Wrapper>
    </Waypoint>
  );
};

ContactWithMap.propTypes = {
  centered: PropTypes.bool,
  contactItems: PropTypes.arrayOf(ContactUsEntryShape),
  mapPoints: PropTypes.arrayOf(MapPointShape),
  screenType: PropTypes.string,
  showContactData: PropTypes.bool,
  staticMapUrl: PropTypes.string,
  onPositionChange: PropTypes.func,
};

ContactWithMap.defaultProps = {
  screenType: '',
  centered: false,
  contactItems: [],
  mapPoints: [],
  showContactData: true,
  staticMapUrl: null,
  onPositionChange: () => {},
};

export default withSizes()(ContactWithMap);
