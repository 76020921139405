import styled, { css } from 'styled-components';

const SliderWrapper = styled.div`
  position: relative;
  width: 100%;

  ${({ isLocalVariant }) => (isLocalVariant && css`
    & .slider-control-bottomcenter {
      bottom: -50px !important;
    }
  `)}
`;

const DotsWrapper = styled.div`
  margin-top: ${({ isLocalVariant }) => (isLocalVariant ? '0px' : '-160px')};
`;

export {
  SliderWrapper, DotsWrapper,
};
