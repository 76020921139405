import PropTypes from 'prop-types';

const MapPointShape = PropTypes.shape({
  lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  route: PropTypes.string,
});

export { MapPointShape };
