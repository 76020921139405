import styled from 'styled-components';


const SelectWrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: -35px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: calc(100% + 15px);
  }
`;

const Label = styled.p`
  font-size: 9px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

const SelectStyles = {
  control: (styles) => ({
    ...styles,
    '&:hover': {
      borderColor: 'black',
    },
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderWidth: '0 0 1px 0',
    borderColor: 'black',
    borderRadius: '0',
    fontSize: '9px',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: 'black',
    height: '54px',
    marginTop: '19px',
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    fontSize: '9px',
    borderRadius: 0,
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    background: isFocused ? '#b1b5b8' : 'white',
    color: isFocused ? 'white' : 'black',
    fontWeight: isFocused ? '600' : '400',
    padding: '15px 20px',
    margin: 0,
  }),
  input: () => ({
    marginLeft: '-7px',
    marginTop: '2px',
    border: 'none',
    fontSize: '9px',
  }),
  placeholder: (styles) => ({
    ...styles,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    fontSize: '9px',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    border: 'none',
    color: 'black',
    marginLeft: '-5px',
    paddingTop: '2px',
  }),
  valueContainer: (styles, { hasValue }) => ({
    ...styles,
    marginBottom: hasValue ? '-25px' : '0',
    marginLeft: hasValue ? '-5px' : 0,
  }),
  dropdownIndicator: (base, { selectProps }) => ({
    ...base,
    transition: 'all .2s ease',
    borderWidth: '8px 6.5px 0 6.5px',
    height: '0',
    width: '0',
    padding: '0',
    borderColor: '#000000 transparent transparent transparent',
    borderStyle: 'solid',
    position: 'absolute',
    bottom: selectProps.menuIsOpen ? '0px' : '-8px',
    right: '0',
    transform: `rotateZ(${selectProps.menuIsOpen ? '180deg' : '0deg'})`,
    svg: {
      display: 'none',
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: 0,
  }),
  menuList: () => ({
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

export {
  SelectWrapper, SelectStyles, Label,
};
