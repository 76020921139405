import styled, { css } from 'styled-components';
import Link from 'components/ui/Link';
import config from 'config';

const Wrapper = styled.div`
  position: relative;
  cursor: default;
  height: 100%;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: ${config.sliderWidth.value}${config.sliderWidth.unit};
  margin: 0 auto;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }
`;

const PromotionWrapper = styled(Link)`
  width: 42%;
  background: ${(props) => props.theme.primary4Color};
  height: 155px;
  position: absolute;
  right: 0;
  bottom: -21px;
  padding: 0 35px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    height: auto;
    padding: 0 0 0 15px;
    width: 68%;
    bottom: 0;
  }
`;

const PromotionText = styled.p`
  color: ${(props) => props.theme.primary2Color};
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.2px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 11px;
  }
`;

const Image = styled.img`
  display: block;
  width: ${({ isHalfWidth }) => (isHalfWidth ? '50%' : '100%')};
  ${({ isHalfWidth }) => isHalfWidth && css`
    margin: 0 auto;
  `}
  ${({ withPointer }) => withPointer && css`
    cursor: pointer;
  `}
`;

export {
  Image, Wrapper, ImageWrapper, PromotionText, PromotionWrapper,
};
