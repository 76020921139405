import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  color: ${(props) => props.theme.secondary13Color};
  font-weight: 500;
  font-size: 18px;
  cursor: default;
  padding: 0 5px;
  position: relative;
  line-height: 1;
  text-transform: uppercase;

  ${(props) => props.size === 'medium' && css`
    font-size: 28px;
  `};

  ${(props) => props.size === 'large' && css`
    font-size: 33px;
  `};

  ${(props) => props.size === 'xlarge' && css`
    font-size: 48px;
  `};

  ${(props) => props.uppercase && css`
    text-transform: uppercase;
  `};

  ${(props) => props.light && css`
    font-weight: 300;
  `};

  ${(props) => props.bold && css`
    font-weight: 700;
  `};

  ${(props) => props.black && css`
    font-weight: 900;
  `};

  ${(props) => props.lightTextColor && css`
    color: ${props.theme.primary2Color};
  `};

  ${(props) => props.boxPosition === 'left' && css`
    padding-left: 17px;

    &::before {
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
    }
  `};

  ${(props) => props.boxPosition === 'bottom' && css`
    padding-left: 0;
    padding-bottom: 25px;

    &::before {
      left: 0;
      bottom: 0;
      width: 30px;
      height: 5px;
    }
  `};

  ${(props) => props.alignCenter && css`
    &::before {
      left: calc(50% - 48px);
    }
  `};

  ${(props) => props.boxPosition === 'bottom' && props.longBox && css`
    &::before {
       width: 91px;
    }
  `};

  ${(props) => props.boxPosition === 'bottom' && props.narrowBox && css`
    &::before {
      height: 2px;
    }
  `};

  &::before {
    display: block;
    content: '';
    background-color: ${(props) => props.theme.primary4Color};
    position: absolute;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 18px;
    padding-bottom: 20px;
    padding-left: 0;
    font-weight: 400;

    &::before {
      display: block;
      content: '';
      background-color: ${(props) => props.theme.primary4Color};
      position: absolute;
      height: 3px;
      bottom: 3px;
      left: 2px;
      width: 70px;
    }
  }
`;

export { Wrapper };
