import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
`;

const Address = styled.a`
  font-size: 13px;
  color: ${(props) => props.theme.secondary9Color};
  font-weight: 700;
  text-align: left;
  letter-spacing: 0.7px;
  text-decoration: none;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 11px;
  }
`;

const Label = styled.div`
  font-weight: 600;
  padding: 0 5px 0 0;
  display: inline;
  color: ${(props) => props.theme.secondary9Color};
  white-space: nowrap;
  font-size: 10.5px;
`;

const Link = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.primary3Color};
  line-height: 13px;
  height: 100%;
  display: flex;
  align-items: center;
`;

const StyledSpan = styled.span`
  font-size: 11px;
  line-height: 21px;
  color: ${(props) => props.theme.primary3Color};
`;

const Column = styled.div`
  padding: 0 25px 0 0;
  display: flex;
  flex-direction: column;

  svg {
    width: 7px;
    height: auto;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 0 30px 0 2px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  line-height: 2;
  letter-spacing: -0.5px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px 0 0;
`;

const CovidInfo = styled.div`
  display: flex;
  align-items: center;
  max-width: 300px;
  word-wrap: break-word;
  padding: 10px;
  border: 1px solid ${(props) => props.theme.primary1Color};
  text-align: center;
  margin-top: 15px;
`;

const EmptySpace = styled.div`
  width: 32px;
`;

const Description = styled.div`
  font-size: 11px;
  font-weight: 700;
  text-align: left;
  letter-spacing: 2px;
  line-height: 2;
  color: ${(props) => props.theme.secondary9Color};
`;

const Email = styled.a`
  color: ${(props) => props.theme.primary4Color};
  font-weight: 800;
  text-align: left;
  text-decoration: none;
  padding: 0;
  margin: 0;
  font-size: 11px;
`;

export {
  Wrapper,
  Column,
  CovidInfo,
  Row,
  EmptySpace,
  IconWrapper,
  Address,
  Label,
  Link,
  StyledSpan,
  Description,
  Email,
};
