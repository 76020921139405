const container = {
  margin: '0 auto',
  width: {
    xs: '100%',
    sm: '750px',
    md: '970px',
    lg: '1170px',
  },
};

export default container;
