import React from 'react';
import PropTypes from 'prop-types';

import Box from './components/Box';
import { StyledLink, Description, Wrapper } from './ContactIcon.styles';

const ContactIcon = ({ href, icon, description }) => (
  <Wrapper>
    <StyledLink route={href}>
      <Box icon={icon} />
      <Description>{description}</Description>
    </StyledLink>
  </Wrapper>
);

ContactIcon.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  description: PropTypes.node,
};

ContactIcon.defaultProps = {
  description: null,
};

export default ContactIcon;
