import React from 'react';
import PropTypes from 'prop-types';

import Display from './components/Display';
import Slider from './components/Slider';
import {
  Wrapper, Container, Title, Row,
} from './RangeSlider.styles';

const RangeSlider = ({
  handleChange,
  firstDisplayValue,
  secondDisplayValue,
  title,
  formatDisplay,
  sliderMin,
  sliderMax,
}) => (
  <Wrapper>
    <Row>
      <Title>
        {title}:
      </Title>
      <Display
        firstValue={firstDisplayValue}
        secondValue={secondDisplayValue}
        format={formatDisplay}
      />
    </Row>
    <Container>
      <Slider
        handleChange={handleChange}
        minValue={sliderMin}
        maxValue={sliderMax}
        minSelected={firstDisplayValue}
        maxSelected={secondDisplayValue}
      />
    </Container>
  </Wrapper>
);

RangeSlider.propTypes = {
  sliderMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  sliderMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  firstDisplayValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formatDisplay: PropTypes.string,
  handleChange: PropTypes.func,
  secondDisplayValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
};

RangeSlider.defaultProps = {
  handleChange: () => {},
  firstDisplayValue: null,
  formatDisplay: null,
  secondDisplayValue: null,
  title: null,
};

export default RangeSlider;
