import React from 'react';
import PropTypes from 'prop-types';
import times from 'lodash/times';

import { Wrapper, Dot } from './Dots.styles';

const Dots = ({
  dotsCount,
  activeDot,
  onClick,
  isLocalVariant,
}) => (
  <Wrapper>
    {
      times(dotsCount, (index) => (
        <Dot
          key={index}
          active={index === activeDot}
          onClick={() => onClick(index)}
          isLocalVariant={isLocalVariant}
        />
      ))
    }
  </Wrapper>
);

Dots.propTypes = {
  activeDot: PropTypes.number,
  dotsCount: PropTypes.number,
  isLocalVariant: PropTypes.bool,
  onClick: PropTypes.func,
};

Dots.defaultProps = {
  activeDot: 0,
  dotsCount: 0,
  isLocalVariant: false,
  onClick: () => {},
};

export default Dots;
