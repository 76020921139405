import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './SectionHeader.styles';

const SectionHeader = ({
  black,
  bold,
  boxPosition,
  children,
  light,
  lightTextColor,
  longBox,
  narrowBox,
  size,
  uppercase,
  alignCenter,
}) => (
  <Wrapper
    boxPosition={boxPosition}
    uppercase={uppercase}
    size={size}
    longBox={longBox}
    narrowBox={narrowBox}
    bold={bold}
    black={black}
    light={light}
    lightTextColor={lightTextColor}
    alignCenter={alignCenter}
  >
    {children}
  </Wrapper>
);

SectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  alignCenter: PropTypes.bool,
  black: PropTypes.bool,
  bold: PropTypes.bool,
  boxPosition: PropTypes.oneOf(['left', 'bottom', 'bottom-left']),
  light: PropTypes.bool,
  lightTextColor: PropTypes.bool,
  longBox: PropTypes.bool,
  narrowBox: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  uppercase: PropTypes.bool,
};

SectionHeader.defaultProps = {
  bold: false,
  black: false,
  boxPosition: 'left',
  light: false,
  lightTextColor: false,
  longBox: false,
  narrowBox: false,
  size: 'small',
  uppercase: true,
  alignCenter: false,
};

export default SectionHeader;
