import React from 'react';
import PropTypes from 'prop-types';
import FullArrow from 'components/icons/FullArrow';
import PhoneEmptyIcon from 'components/icons/PhoneEmpty';
import MailIcon from 'components/icons/Mail';
import { FormattedMessage } from 'react-intl';
import FooterCopyright from 'components/FooterCopyright/FooterCopyright';
import ContactPerson
  from 'components/HeaderMobile/components/ContactDetails/components/ContactPerson/ContactPerson';

import { SCROLLABLE_ELEMENT_CLASS_NAME } from 'utils/bodyScrollLock';
import messages from './ContactDetails.messages';
import { ContactItemsShape } from './ContactDetails.shapes';
import { ContactPersonShape } from './components/ContactPerson/ContactPerson.shapes';
import {
  Wrapper,
  AddressContainer,
  Container,
  Row,
  Text,
  TextContainer,
  PhoneNumberButton,
  AddressDescription,
  EmailButton,
  ContactPeopleContainer,
  FooterContainer,
  PhoneButton,
  RelativeContainer,
} from './ContactDetails.styles';

const ContactDetails = ({
  onPhoneIconClick, opened, contactItems, email, contactPeople,
}) => (
  <React.Fragment>
    <PhoneButton onClick={onPhoneIconClick} active={opened}>
      <PhoneEmptyIcon />
    </PhoneButton>
    <Wrapper visible={opened}>
      <RelativeContainer className={SCROLLABLE_ELEMENT_CLASS_NAME}>
        <AddressContainer>
          {
          contactItems.map((elem, key) => (
            <Container key={key}>
              <Row>
                <FullArrow width="6px" height="100%" />
                <TextContainer>
                  <Text>{elem.address}
                  </Text>
                  <AddressDescription>
                              | {elem.description}
                  </AddressDescription>
                </TextContainer>
              </Row>
              <PhoneNumberButton href={`tel:${elem.phone}`}>
                <PhoneEmptyIcon width="12px" height="100%" />
                {elem.phone}
              </PhoneNumberButton>
            </Container>
          ))
        }
          <Container>
            <Row>
              <FullArrow width="8px" height="100%" />
              <TextContainer>
                <Text>
                  <FormattedMessage {...messages.writeMessage} />
                </Text>
              </TextContainer>
            </Row>
            <EmailButton href={`mailto:${email}`}>
              <MailIcon width="12px" height="100%" />
              {email}
            </EmailButton>
          </Container>
        </AddressContainer>
        <ContactPeopleContainer>
          {
          contactPeople.map((contactPerson, key) => (
            <ContactPerson
              contactPerson={contactPerson}
              key={key}
            />
          ))
        }
        </ContactPeopleContainer>
      </RelativeContainer>
      <FooterContainer>
        <FooterCopyright />
      </FooterContainer>
    </Wrapper>
  </React.Fragment>
);

ContactDetails.propTypes = {
  contactItems: PropTypes.arrayOf(ContactItemsShape).isRequired,
  contactPeople: PropTypes.arrayOf(ContactPersonShape).isRequired,
  email: PropTypes.string.isRequired,
  onPhoneIconClick: PropTypes.func.isRequired,
  opened: PropTypes.bool,
};

ContactDetails.defaultProps = {
  opened: false,
};

export default ContactDetails;
