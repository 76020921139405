import React from 'react';
import PropTypes from 'prop-types';

import SubmenuBox from 'components/ui/SubmenuBox';
import { MenuItemShape } from 'components/ui/SubmenuBox/SubmenuBox.shapes';
import config from 'config';
import trackYouleadVirtualPageView from 'utils/YouLead/TrackYouleadVirtualPageView';
import HorizontalMenuLink from '../HorizontalMenuLink';

import {
  Wrapper,
  Expanded,
} from './HorizontalMenuEntry.styles';

class HorizontalMenuEntry extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(MenuItemShape),
    last: PropTypes.bool,
  }

  static defaultProps = {
    items: [],
    last: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      hovered: false,
    };

    this.leaveTimeout = null;
  }

  onEntry = () => {
    clearTimeout(this.leaveTimeout);
    this.setState({ hovered: true });
  }

  onLeave = () => {
    this.leaveTimeout = setTimeout(
      () => this.setState({ hovered: false }),
      config.menuDisappearTimeout,
    );
  }

  render() {
    return (
      <Wrapper
        onMouseEnter={this.onEntry}
        onMouseLeave={this.onLeave}
      >
        <HorizontalMenuLink {...this.props} hovered={this.state.hovered} trackPage={trackYouleadVirtualPageView} />
        {this.state.hovered && this.props.items.length > 0 ? (
          <Expanded last={this.props.last}>
            <SubmenuBox topBorder items={this.props.items} />
          </Expanded>
        ) : null}
      </Wrapper>
    );
  }
}

export default HorizontalMenuEntry;
