import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  background-color: transparent;
  color: ${(props) => props.theme.primary3Color};
  text-transform: uppercase;
  padding: 0 23px;
  min-height: 55px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  letter-spacing: 1.2px;

  svg {
    fill: ${(props) => props.theme.secondary8Color};
  }

  ${(props) => props.active && css`
    background-color: ${props.theme.secondary7Color};
    font-weight: 600;
  `}

  &:hover {
    background-color: ${(props) => props.theme.secondary8Color};
    color: ${(props) => props.theme.primary2Color};
    font-weight: 600;

    svg {
      fill: ${(props) => props.theme.primary2Color};
    }
  }
`;

export { Wrapper };
