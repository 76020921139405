import styled from 'styled-components';
import { ContainerMain } from 'utils/styles';
import Link from 'components/ui/Link';

const Wrapper = styled.div`
  background: ${(props) => props.theme.secondary11Color};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const Container = styled(ContainerMain)`
  padding: 20px 0 8px;
  display: flex;

  flex-wrap: wrap;
  background: ${(props) => props.theme.secondary11Color};
  justify-content: space-between;
  font-size: 12px;
  letter-spacing: 1.2px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    justify-content: center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 8px;
    letter-spacing: 0;
    padding: 11px 0 8px;
  }
`;

const CopyrightWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 15px;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding-left: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
    margin-top: -15px;
    flex-basis: 100%;
    text-align: center;
    padding-left: 0;
  }
`;

const SocialLinksWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
    order: -1;
    padding-right: 10px;
  }
`;

const InformationLinkWrapper = styled(Link)`
  text-transform: uppercase;
  padding-right: 15px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    &::before {
      content: "|";
      margin: 0 5px;
    }
    letter-spacing: 0.8px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
    &::before {
      content: "";
    }
    padding-right: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    letter-spacing: 2.5px;
    padding-right: 185px;
  }
`;

export {
  CopyrightWrapper,
  SocialLinksWrapper,
  Container,
  Wrapper,
  InformationLinkWrapper,
};
