import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 50%;
  margin-bottom: 40px;
`;

export const Image = styled.img`
  height: 55px;
  width: 55px;
  border: 2px solid ${(props) => props.theme.primary1Color};
  border-radius: 100%;
  object-fit: cover;
  object-position: top;
`;

export const Name = styled.div`
  font-size: 11px;
  font-weight: 600;
  letter-spacing: -0.5px;
  margin-top: 5px;
`;

export const Phone = styled.div`
  font-size: 10px;
  letter-spacing: -0.5px;
  margin: 5px 0;
`;

export const Position = styled.div`
  font-size: 9px;
  font-weight: 600;
`;
