import React from 'react';
import PropTypes from 'prop-types';

import isUndefined from 'lodash/isUndefined';

import {
  lockBody, unlockBody,
} from 'utils/bodyScrollLock';

import { HorizontalMenuItemShape } from 'components/Header/components/HorizontalMenu/HorizontalMenu.shapes';
import { ContactItemsShape } from 'components/HeaderMobile/components/ContactDetails/ContactDetails.shapes';
import { ContactPersonShape } from 'components/HeaderMobile/components/ContactDetails/components/ContactPerson/ContactPerson.shapes';
import Logo from './components/Logo';
import BurgerMenu from './components/BurgerMenu';
import ContactDetails from './components/ContactDetails';

import {
  Wrapper,
  MenuWrapper,
  FavouritesWrapper,
  HeaderPlaceholder,
} from './HeaderMobile.styles';
import Favourites from '../Header/components/Favourites/Favourites';

class HeaderMobile extends React.Component {
  static propTypes = {
    contactItems: PropTypes.arrayOf(ContactItemsShape).isRequired,
    contactPeople: PropTypes.arrayOf(ContactPersonShape).isRequired,
    email: PropTypes.string.isRequired,
    favouritesCount: PropTypes.number.isRequired,
    menuItems: PropTypes.arrayOf(HorizontalMenuItemShape),
  };

  static defaultProps = {
    menuItems: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      burgerMenuIsOpen: false,
      contactPageIsOpen: false,
    };
  }

  componentWillUnmount() {
    unlockBody();
  }

  toggleContactPage = () => {
    this.setState((prevState) => ({
      contactPageIsOpen: !prevState.contactPageIsOpen,
      burgerMenuIsOpen: false,
    }), this.toggleBodyClass);
  }

  toggleBurgerMenu = (externalControlValue) => {
    this.setState((prevState) => {
      if (isUndefined(externalControlValue)) {
        return {
          contactPageIsOpen: false,
          burgerMenuIsOpen: !prevState.burgerMenuIsOpen,
        };
      }

      const newState = {
        burgerMenuIsOpen: externalControlValue,
      };

      if (newState.burgerMenuIsOpen) newState.contactPageIsOpen = false;

      return newState;
    }, this.toggleBodyClass);
  }

  toggleBodyClass = () => {
    if (!this.state.burgerMenuIsOpen && !this.state.contactPageIsOpen) {
      unlockBody();
    } else {
      lockBody();
    }
  }

  onBurgerMenuStateChange = ({ isOpen }) => {
    this.toggleBurgerMenu(isOpen);
  }

  render() {
    const {
      favouritesCount, contactItems, email, contactPeople, menuItems,
    } = this.props;
    return (
      <React.Fragment>
        <Wrapper>
          <Logo />
          <FavouritesWrapper>
            <Favourites favouritesCount={favouritesCount} mobileVersion />
          </FavouritesWrapper>
        </Wrapper>
        <ContactDetails
          contactItems={contactItems}
          email={email}
          contactPeople={contactPeople}
          opened={this.state.contactPageIsOpen}
          onPhoneIconClick={this.toggleContactPage}
        />
        <MenuWrapper>
          <BurgerMenu
            menuItems={menuItems}
            opened={this.state.burgerMenuIsOpen}
            onStateChange={this.onBurgerMenuStateChange}
            toggleBurgerMenu={this.toggleBurgerMenu}
          />
        </MenuWrapper>
        <HeaderPlaceholder />
      </React.Fragment>
    );
  }
}

export default HeaderMobile;
