const variables = {
  primary1Color: '#e30613',
  primary2Color: '#fff',
  primary3Color: '#0a0a0a',
  primary4Color: '#e30613',
  secondary1Color: '#8b1515',
  secondary2Color: '#f3f5f4',
  secondary3Color: '#ffffff',
  secondary4Color: '#bc0d0d',
  secondary5Color: '#f8f8f8',
  secondary6Color: '#ebebeb',
  secondary7Color: '#e1e1e1',
  secondary8Color: '#b1b5b8',
  secondary9Color: '#575756',
  secondary11Color: '#f6f6f6',
  secondary12Color: '#3c3c3b',
  secondary13Color: '#454851',
  secondary14Color: '#222533',
  secondary15Color: '#d7d7d7',
  backgroundColor: '#ffffff',
  statusPrzygotowany: '#7c7c7c',
  statusSprzedane: '#3d3d3b',
  statusWolne: '#8fae77',
  statusReserved: '#fff',
  statusPromotion: '#b7790c',
  statusWybrany: '#b70e0c',
  sliderBackgroundColor: '#f0f0f0',
  mapColor1: '#6b6b6b',
  mapColor2: '#d1d1d1',
  inputColor: '#dadada',
  shadowColor: '0, 0, 0, 0.61',
  containerWidth: 1170,
  animationDuration: '500ms',
  fontSize: '16px',
  fontSizeSecondary: '14px',
  fontSizeMobile: '10px',
  inputSize: '58px',
  inputSizeMobile: '32px',
  pink: '#ff00ff',
  darkGray: '#5d5d5d',
  lightGray: '#f3f3f3',
  gray: '#dadada',
  white: '#fff',
  black: '#000',
};

export default {
  ...variables,
  linkColor: variables.primary1Color,
  paragraphLineHeight: 2,
};
