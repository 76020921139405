import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Link from 'components/ui/Link';

import isPromotionActive from 'utils/isPromotionActive';
import { LinkShape } from './Slide.shapes';

import {
  Image, Wrapper, ImageWrapper, PromotionText, PromotionWrapper,
} from './Slide.styles';

const handleImageLoaded = () => {
  window.dispatchEvent(new Event('resize'));
};

const Slide = ({
  imageUrl,
  link,
  promo,
  setDotsVisibility,
  showModal,
  isHalfWidth,
}) => {
  const imageRef = useRef(null);
  let imageComponent = (
    <Image
      innerRef={imageRef}
      src={imageUrl}
      alt="slide"
      draggable={false}
      onLoad={handleImageLoaded}
      withPointer={!!showModal}
      onClick={() => { if (showModal) { showModal(imageUrl); } }}
      isHalfWidth={isHalfWidth}
    />
  );

  useEffect(() => {
    const img = imageRef.current;

    if (img.height > 0) {
      setDotsVisibility(true);
    }
  }, [imageRef, imageRef.current && imageRef.current.height]);

  if (link && link.href) {
    imageComponent = (
      <Link
        href={link.href}
        route={link.route}
        params={link.params}
        target="_top"
        draggable={false}
      >
        {imageComponent}
      </Link>
    );
  }

  return (
    <Wrapper>
      <ImageWrapper>
        {imageComponent}
      </ImageWrapper>
      {
        promo && isPromotionActive(promo.entity.date) ? (
          <PromotionWrapper route="promotionsList" params={{ page: 1 }}>
            <PromotionText>
              {promo.entity.name}
            </PromotionText>
          </PromotionWrapper>
        ) : null
      }
    </Wrapper>
  );
};

Slide.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  isHalfWidth: PropTypes.bool,
  link: LinkShape,
  promo: PropTypes.shape({
    entity: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  }),
  setDotsVisibility: PropTypes.func,
  showModal: PropTypes.func,
};

Slide.defaultProps = {
  link: null,
  promo: null,
  setDotsVisibility: () => {},
  showModal: undefined,
  isHalfWidth: false,
};

export default Slide;
