import React from 'react';
import { FormattedMessage } from 'react-intl';
import { VisibilityContainer } from 'utils/styles';

import SectionHeader from 'components/ui/SectionHeader';

import messages from './ContactUsTitle.messages';
import {
  TitleBold,
  Title,
  TopHeader,
} from './ContactUsTitle.styles';

const ContactUsTitle = () => (
  <div>
    <TopHeader>
      <VisibilityContainer md lg>
        <FormattedMessage {...messages.office} />
      </VisibilityContainer>
    </TopHeader>
    <SectionHeader alignCenter longBox uppercase={false} boxPosition="bottom" size="large">
      <TitleBold><FormattedMessage {...messages.contact} /> </TitleBold>
      <Title><FormattedMessage {...messages.us} /></Title>
    </SectionHeader>
  </div>
);

export default ContactUsTitle;
