import React from 'react';
import PropTypes from 'prop-types';

import POICulture from 'components/icons/POICulture';
import POIGastronomy from 'components/icons/POIGastronomy';
import POIGym from 'components/icons/POIGym';
import POIHealth from 'components/icons/POIHealth';
import POIPost from 'components/icons/POIPost';
import POIRecreation from 'components/icons/POIRecreation';
import POISchool from 'components/icons/POISchool';
import POIService from 'components/icons/POIService';
import POIShop from 'components/icons/POIShop';
import OfficeMarker from 'components/icons/OfficeMarker';
import InvestmentMarker from 'components/icons/InvestmentMarker';

import {
  PoiTooltip,
  InvestmentMarkerWrapper,
  InvestmentTooltip,
  OfficeMarkerWrapper,
  OfficeTooltip,
  MarkerWrapper,
} from './MapMarkerFactory.styles';

const definedPoITypes = [
  'POIGastronomy', 'POIGym', 'POIRecreation', 'POISchool', 'POIService', 'POIShop',
];

const OFFICE = 'Office';
const INVESTMENT = 'Investment';

const getIcon = (point) => {
  switch (point.type) {
    case 'POICulture':
      return <POICulture />;
    case 'POIGastronomy':
      return <POIGastronomy />;
    case 'POIGym':
      return <POIGym />;
    case 'POIHealth':
      return <POIHealth />;
    case 'POIPost':
      return <POIPost />;
    case 'POIRecreation':
      return <POIRecreation />;
    case 'POISchool':
      return <POISchool />;
    case 'POIOther':
      return <POIService />;
    case 'POIShop':
      return <POIShop />;
    case OFFICE:
      return <OfficeMarker />;
    case INVESTMENT:
      return <InvestmentMarker />;
    default:
      return null;
  }
};

const getTooltip = (
  {
    name,
    checked,
    hideTooltip,
    disabled,
    highlighted,
  },
  type,
) => {
  switch (type) {
    case OFFICE:
      return (
        <OfficeTooltip
          disabled={disabled}
        >
          {name}
        </OfficeTooltip>
      );
    case INVESTMENT:
      return (
        <InvestmentTooltip
          disabled={disabled}
          hideTooltip={hideTooltip}
          highlighted={highlighted}
          checked={checked}
        >
          {name}
        </InvestmentTooltip>
      );
    default:
      return (
        <PoiTooltip
          hideTooltip={hideTooltip}
        >
          {name}
        </PoiTooltip>
      );
  }
};

const MapMarkerFactory = (props) => {
  const icon = getIcon(props);
  const hideTooltipIfUnchecked = !props.isChecked && props.hideTooltipIfUnchecked;
  const disabled = props.disabled;

  if (props.type === INVESTMENT) {
    return (
      <InvestmentMarkerWrapper
        onClick={() => props.onClick(props.investmentId, props.investmentSlug, props.type, props.route)}
        checked={props.isChecked}
        disabled={disabled}
        highlighted={props.highlighted}
      >
        {
          hideTooltipIfUnchecked ? null
            : getTooltip(
              {
                highlighted: props.highlighted,
                disabled: props.disabled,
                name: props.name,
                hideTooltip: props.name === '',
                checked: props.isChecked,
              },
              props.type,
            )
        }
        {icon}
      </InvestmentMarkerWrapper>
    );
  }

  if (props.type === OFFICE) {
    return (
      <OfficeMarkerWrapper
        onClick={() => props.onClick(props.investmentId, props.investmentSlug, props.type, props.route, props.name)}
        disabled={disabled}
      >
        {
          getTooltip(
            {
              disabled: props.disabled,
              name: props.name,
            },
            props.type,
          )
        }
        {icon}
      </OfficeMarkerWrapper>
    );
  }

  return (
    <MarkerWrapper>
      {
        getTooltip({
          name: props.name,
          hideTooltip: definedPoITypes.indexOf(props.type) === -1 || props.name === '',
        })
      }
      {icon}
    </MarkerWrapper>
  );
};

MapMarkerFactory.propTypes = {
  disabled: PropTypes.bool,
  hideTooltipIfUnchecked: PropTypes.bool,
  highlighted: PropTypes.bool,
  investmentId: PropTypes.number,
  investmentSlug: PropTypes.string,
  isChecked: PropTypes.bool,
  name: PropTypes.string,
  route: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

MapMarkerFactory.defaultProps = {
  name: undefined,
  type: undefined,
  route: undefined,
  highlighted: false,
  onClick: () => {},
  investmentId: 0,
  investmentSlug: '',
  isChecked: false,
  disabled: false,
  hideTooltipIfUnchecked: false,
};

export default MapMarkerFactory;
