import { defineMessages } from 'react-intl';

export default defineMessages({
  from: {
    id: 'Display.from',
    defaultMessage: 'OD',
  },
  to: {
    id: 'Display.to',
    defaultMessage: 'DO',
  },
});
