import config from 'config';
import messages from './breadcrumbs.messages';

const Breadcrumbs = ({
  intl, investment, title, pageTitle, pageDescription, imageUrl, pathParts, isPageTitleRaw = false,
}) => {
  const output = {
    title: title || intl.formatMessage(messages.home),
    pageTitle: pageTitle || '',
    pageDescription: pageDescription || config.description,
    imageUrl: imageUrl || null,
    pathParts: [{
      id: 'home',
      name: intl.formatMessage(messages.home),
      route: '/',
    }],
    isPageTitleRaw,
  };

  if (investment !== undefined) {
    output.pathParts.push({
      id: 'investment',
      name: investment.title,
      route: 'investmentDescription',
      params: { id: investment.id },
    });
  }

  if (pathParts !== undefined) {
    pathParts.forEach((el) => {
      output.pathParts.push(el);
    });
  }

  return output;
};

export default Breadcrumbs;
