import styled from 'styled-components';

const TitleBold = styled.span`
  font-weight: 900;
`;

const Title = styled.span`
  font-weight: 300;
`;

const TopHeader = styled.div`
  font-weight: 400;
  font-size: 17px;
  padding: 0 0 15px;
`;

export {
  TitleBold,
  Title,
  TopHeader,
};
