import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';
import _map from 'lodash/map';

import config from 'config';

import { VisibilityContainer } from 'utils/styles';

import Dots from './components/Dots';
import Slide from './components/Slide';
import { SliderItemShape } from './Slider.shapes';
import {
  SliderWrapper,
  DotsWrapper,
} from './Slider.styles';

const Slider = ({ items, variant, showModal }) => {
  const [dotsVisibility, setDotsVisibility] = useState();
  const isLocalVariant = variant === 'local';

  const DotsContainer = isLocalVariant ? React.Fragment : VisibilityContainer;
  const dotsContainerProps = isLocalVariant ? {} : { lg: true };

  return (
    <SliderWrapper isLocalVariant={isLocalVariant}>
      <Carousel
        autoplay
        autoplayInterval={config.slider.autoplayInterval}
        wrapAround
        renderCenterLeftControls={() => {}}
        renderCenterRightControls={() => {}}
        renderBottomCenterControls={({
          currentSlide,
          slideCount,
          goToSlide,
        }) => (!isLocalVariant || dotsVisibility) && (
          <DotsContainer {...dotsContainerProps}>
            <DotsWrapper isLocalVariant={isLocalVariant}>
              <Dots
                onClick={goToSlide}
                dotsCount={slideCount}
                activeDot={currentSlide}
                isLocalVariant={isLocalVariant}
              />
            </DotsWrapper>
          </DotsContainer>
        )}
        swiping
        pauseOnHover={false}
      >
        {
          _map(items, (el, key) => (
            <Slide
              key={key}
              setDotsVisibility={setDotsVisibility}
              showModal={showModal}
              {...el}
            />
          ))
        }
      </Carousel>
    </SliderWrapper>
  );
};

Slider.propTypes = {
  items: PropTypes.arrayOf(SliderItemShape),
  showModal: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'local']),
};

Slider.defaultProps = {
  items: [],
  variant: 'default',
  showModal: () => {},
};

export default Slider;
