import styled from 'styled-components';
import { ContainerMain } from 'utils/styles';
import config from 'config';

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: ${config.sliderWidth.value}${config.sliderWidth.unit};
  margin: 0 auto;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }
`;

const BreadcrumbsWrapper = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  vertical-align: middle;
`;

const Container = styled(ContainerMain)`
  display: flex;
  align-items: center;
  height: 100%;
`;

const Content = styled.div`
  height: 50px;
  width: 41.5vw;
  background: ${(props) => props.theme.secondary12Color};
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const PathWrapper = styled.div`
  margin: 0 125px 0;
  display: flex;
  justify-content: right;
  width: max-content;

  > div {
    display: flex;
    align-items: center;
  }
`;

const PathPart = styled.div`
  color: ${(props) => props.theme.primary2Color};
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Delimiter = styled.div`
  padding: 2px 15px 0;
  display: flex;
  align-items: center;
`;

export {
  Delimiter,
  PathPart,
  PathWrapper,
  Content,
  Container,
  Image,
  ImageWrapper,
  BreadcrumbsWrapper,
};
