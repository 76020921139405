import styled from 'styled-components';

const SearchButton = styled.button`
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${(props) => props.theme.secondary7Color};
  display: flex;
  align-items: center;
  margin-left: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 40px 0 30px auto;
    height: 30px;
  }
`;

const ButtonText = styled.div`
  color: ${(props) => props.theme.primary3Color};
  text-transform: uppercase;
  font-size: 14px;
  margin-right: 10px;
  font-weight: 800;
  letter-spacing: 1.2px;
  padding: 0 30px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 11px;
    padding: 0 30px;
    letter-spacing: 1.3px;
  }
`;

const Square = styled.div`
  height: 50px;
  width: 50px;
  background-color: ${(props) => props.theme.primary4Color};
  fill: ${(props) => props.theme.secondary3Color};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;

  svg {
    transform: scale(1.15);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 18px;
    width: 18px;
  }
`;

export {
  SearchButton, ButtonText, Square, Icon,
};
