import styled from 'styled-components';
import Link from 'components/ui/Link';

export const Button = styled(Link)`
  border: 1px solid ${(props) => props.theme.secondary8Color};
  text-transform: uppercase;
  letter-spacing: 1.1px;
  color: ${(props) => props.theme.secondary8Color} !important;
  width: calc(100% - 80px);
  border-radius: 3px;
  background: white;
  height: 40px;
  margin: 8px 20px 3px 20px;
  font-size: 9px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 10px;

  svg {
    width: 20px;
  }
`;
