import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Select from 'react-select';
import find from 'lodash/find';

import intlShape from 'utils/shapes/intlShape';

import { SelectWrapper, SelectStyles, Label } from './Select.styles';
import { SelectOptionShape } from './Select.shapes';
import { formatValues } from './utils';
import messages from './Select.messages';

const SelectOption = ({
  values,
  selectedOption,
  placeHolder,
  intl,
  onChange,
  onMenuClose,
  name,
  withEmptyOption,
  optionComponent,
}) => {
  const options = formatValues(values, withEmptyOption, intl.formatMessage(messages.all));
  const selected = find(options, (e) => e.value === selectedOption) || null;
  const placeholder = placeHolder || intl.formatMessage(messages.all);
  const components = {};

  if (optionComponent) components.Option = optionComponent;

  return (
    <SelectWrapper>
      <Label>
        {name}
      </Label>
      <Select
        isSearchable={false}
        options={options}
        noOptionsMessage={() => (intl.formatMessage(messages.noOptions))}
        placeholder={placeholder}
        styles={SelectStyles}
        onChange={onChange}
        onMenuClose={onMenuClose}
        value={selected}
        components={components}
      />
    </SelectWrapper>
  );
};

SelectOption.propTypes = {
  intl: intlShape.isRequired,
  values: PropTypes.arrayOf(SelectOptionShape).isRequired,
  name: PropTypes.string,
  optionComponent: PropTypes.func,
  placeHolder: PropTypes.string,
  selectedOption: PropTypes.number,
  withEmptyOption: PropTypes.bool,
  onChange: PropTypes.func,
  onMenuClose: PropTypes.func,
};

SelectOption.defaultProps = {
  selectedOption: undefined,
  name: null,
  placeHolder: null,
  withEmptyOption: false,
  optionComponent: null,
  onMenuClose: () => {},
  onChange: () => {},
};

export default injectIntl(SelectOption);
