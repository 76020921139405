import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import { withContentRect } from 'react-measure';
import GoogleMapReact from 'google-map-react';
import { fitBounds } from 'google-map-react/utils';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import some from 'lodash/some';
import config from 'config';

import includes from 'lodash/includes';
import MapMarkerFactory from './components/MapMarkerFactory';
import { MapPointShape } from './GoogleMaps.shapes';
import { Wrapper, StaticWrapper, Empty } from './GoogleMaps.styles';
import messages from './GoogleMaps.messages';
import googleMapsStyles from './googleMapsStyles';

const MAX_ZOOM = 17;
const DEFAULT_ZOOM = 12;
const DEFAULT_ZOOM_SINGLE = 17;

const renderMarkers = (
  points,
  onClick,
  checkedInvestments,
  hideTooltipIfUnchecked,
) => points.map((el, key) => (
  <MapMarkerFactory
    lat={el.lat}
    lng={el.lng}
    name={el.name}
    route={el.route}
    type={el.type}
    disabled={el.disabled}
    highlighted={el.highlighted}
    key={key}
    onClick={onClick}
    investmentId={el.id}
    investmentSlug={el.slug}
    checkedInvestments={checkedInvestments}
    hideTooltipIfUnchecked={hideTooltipIfUnchecked}
    isChecked={includes(checkedInvestments, el.id)}
  />
));

const findBoundsPoint = (points) => {
  const lats = points.map((el) => el.lat);
  const lngs = points.map((el) => el.lng);

  const minLat = Math.min(...lats);
  const maxLat = Math.max(...lats);
  const minLng = Math.min(...lngs);
  const maxLng = Math.max(...lngs);

  return {
    nw: {
      lat: maxLat,
      lng: minLng,
    },
    se: {
      lat: minLat,
      lng: maxLng,
    },
  };
};

class GoogleMaps extends React.Component {
  static propTypes = {
    contentRect: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    measureRef: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    center: MapPointShape,
    height: PropTypes.number,
    hideTooltipIfUnchecked: PropTypes.bool,
    mapKey: PropTypes.string,
    points: PropTypes.arrayOf(MapPointShape),
    staticMapUrl: PropTypes.string,
    zoom: PropTypes.number,
  };

  static defaultProps = {
    points: [],
    center: null,
    height: null,
    mapKey: 'mapKey',
    staticMapUrl: null,
    zoom: null,
    hideTooltipIfUnchecked: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      renderStatic: props.staticMapUrl !== null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return ((this.state.renderStatic !== nextState.renderStatic)
      || (this.props.center !== nextProps.center)
      || (this.props.height !== nextProps.height)
      || (this.props.zoom !== nextProps.zoom)
      || (isEqual(this.props.contentRect.bounds, nextProps.contentRect.bounds))
      || (isEqual(this.props.points, nextProps.points)));
  }

  changeToDynamicMap = () => this.setState({ renderStatic: false });

  isEmptyPoints = (points) => {
    if (isEmpty(this.props.points)) {
      return true;
    }

    return !some(points, (el) => el.lat && el.lng);
  }

  render() {
    let content = null;

    if (this.isEmptyPoints(this.props.points)) {
      content = (
        <Empty>
          <FormattedMessage {...messages.empty} />
        </Empty>
      );
    } else if (isEmpty(this.props.contentRect.bounds) || this.state.renderStatic) {
      content = (
        <StaticWrapper onClick={this.changeToDynamicMap} mapUrl={this.props.staticMapUrl} />
      );
    } else {
      const size = {
        width: this.props.contentRect.bounds.width,
        height: this.props.contentRect.bounds.height,
      };

      const uniqPoints = uniqWith(this.props.points, isEqual);

      let calculatedBounds = {
        center: {
          lat: parseFloat(uniqPoints[0].lat),
          lng: parseFloat(uniqPoints[0].lng),
        },
        zoom: DEFAULT_ZOOM_SINGLE,
      };

      if (uniqPoints.length > 1) {
        calculatedBounds = fitBounds(findBoundsPoint(uniqPoints), size);
      }

      const finalCenter = this.props.center || calculatedBounds.center;
      let finalZoom = this.props.zoom || calculatedBounds.zoom || DEFAULT_ZOOM;

      if (finalZoom > MAX_ZOOM) {
        finalZoom = MAX_ZOOM;
      }

      content = (
        <GoogleMapReact
          key={this.props.mapKey}
          defaultCenter={finalCenter}
          defaultZoom={finalZoom}
          options={{
            styles: googleMapsStyles(this.props.theme),
            zoomControl: false,
            fullscreenControl: false,
          }}
          bootstrapURLKeys={{ key: config.google.mapKey }}
        >
          {renderMarkers(
            uniqPoints,
            this.props.onMarkerClick,
            this.props.checkedInvestments,
            this.props.hideTooltipIfUnchecked,
          )
          }
        </GoogleMapReact>
      );
    }

    return (
      <Wrapper innerRef={this.props.measureRef} mapHeight={this.props.height}>
        {content}
      </Wrapper>
    );
  }
}

export default withTheme(withContentRect('bounds')(GoogleMaps));
